
import { defineComponent, ref } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tabIndex: ref(0),
      tableHeader: [
        {
          name: "Day",
          key: "day",
          sortable: true,
          width: "5px",
        },
        {
          name: "Training Date",
          key: "training_date",
          sortable: true,
        },
        {
          name: "Training Day",
          key: "training_day",
          sortable: true,
        },
        {
          name: "Start time",
          key: "start_time",
          sortable: true,
        },
        {
          name: "End Time",
          key: "end_time",
          sortable: true,
        },
        {
          name: "Daily Hour",
          key: "daily_hour",
          sortable: true,
        },
      ],
      tableData: [
        {
          day: 1,
          training_day: "ABC",
          training_date: "A.B.C Bangladesh-Turkish Technical School",
          start_time: "Nazirhat Municipality ,Fatickchari ",
          end_time: "0312552697 ",
          daily_hour: "abcbdtktschool@yahoo.com",
        },
        // {
        //   id: 2,
        //   short_name: "ACA",
        //   name: "ACA Technical Institute ",
        //   address: "Faterpur, Enayetnagar, Matlab Uttor, Chandpur ",
        //   phone: "0312510677  ",
        //   mail: "	aca.seip@gmail.com ",
        //   website: "www.aca.org ",
        // },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          console.log(response);
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          console.log(response);
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    // setActiveTab(event) {
    //   this.tabIndex = parseInt(event.target.getAttribute("data-tab-index"));
    // },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
